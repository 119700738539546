import React from "react"
import styled from 'styled-components';
import SEO from '../components/seo/SEO'
import Layout from '../layout/layout2'
import { Container, Section, media } from "../utils/utils";

const Opis = styled(Container)`
  p {
    white-space: pre-wrap;
    margin-top: 2rem;
  }
`;

const Przeciwskazania = styled(Container)`
  h4 {
    margin-bottom: 4rem;
  }

  > div {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 6rem;
    ${media.lessThan("small")`
    grid-template-columns: 1fr;
    `}
     li {
       margin-bottom: .5rem;
     }
  }
  
`;

const Makijaz = ({ data }) => {
  const makeup = data.datoCmsMakeupPage
  return (
    <div>
      <SEO
        title='Makijaż'
        slug='/makijaz' />
      <Layout>
        <Section
          bgColor={({ theme }) => theme.color.baseExtraLight}>
          <Opis>
            <h1>Makijaż permanentny</h1>
            <p>{makeup.opisZabiegu}</p>
          </Opis>
        </Section>
        <Section>
          <Przeciwskazania>
            <h4>Przeciwwskazania do zabiegu makijazu permanentnego:</h4>
            <div>
              <div dangerouslySetInnerHTML={{ __html: makeup.przeciwwskazaniaLeftNode.childMarkdownRemark.html }} />
              <div dangerouslySetInnerHTML={{ __html: makeup.przeciwwskazaniaRightNode.childMarkdownRemark.html }} />
            </div>
          </Przeciwskazania>
        </Section>
      </Layout>
    </div>
  )
}
export default Makijaz

export const query = graphql`
query {
  datoCmsMakeupPage {
    opisZabiegu
    przeciwwskazaniaLeftNode {
      childMarkdownRemark {
        html
      }
    }
    przeciwwskazaniaRightNode {
      childMarkdownRemark {
        html
      }
    }
  }
}`
